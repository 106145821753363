import React from 'react';

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

import { Container } from './styles';

const Socials: React.FC = () => {
	return (
		<Container className="wrapper">
			<SideMenu menuActive="textos" />

			<div className="rightSide">
				<Header />
			</div>
		</Container>
	);
};

export default Socials;
