import styled from 'styled-components';

import { mainColors } from '../../styles/colors';

export const Container = styled.aside`
	width: 230px;
	height: 100vh;
	background-color: #fff;

	padding-top: 0;
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);

	position: fixed;
`;

export const Logo = styled.div`
	width: 100%;
	height: 85px;
	padding: 15px 5px;

	display: flex;
	justify-content: center;

	img {
		width: 80%;
		margin: 0 auto;
	}
`;

export const List = styled.ul`
	padding: 30px 0;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ListItem = styled.li`
	width: 100%;
	list-style: none;

	a {
		text-decoration: none;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding: 0.8rem 1rem;
		border-radius: 0.375rem;

		display: flex;
		align-items: center;

		span {
			color: #525f7f;
			margin-left: 14px;

			font-size: 14px;
			line-height: 22px;
		}

		&:hover span {
			color: ${mainColors.orangeSite};
		}
	}

	.selected {
		background: #5e72e475;
	}

	.selected span {
		color: #40495e;
	}

	.icoDashboard {
		color: #5e72e4;
	}

	.icoHome {
		color: #fb6340;
	}

	.icoPages {
		color: #ffd600;
	}

	.icoGallery {
		color: #212529;
	}

	.icoSocial {
		color: #11cdef;
	}
`;
