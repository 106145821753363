import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';

import LoadingPage from '../../components/LoadingPage';

import { Container, PageCard, CardTitle, CardHeader, Card } from './styles';

interface IContentsProps {
	id: number;
	name: string;
	page: string;
}

const CONTENTS = gql`
	query {
		contents {
			id
			name
			page
		}
	}
`;

const UPDATE_CONTENT = gql`
	mutation updateContent($id: ID, $is_active: Int) {
		updateContent(id: $id, is_active: $is_active) {
			id
		}
	}
`;

const ContentsTable: React.FC = () => {
	const [dataSchool, setDataSchool] = useState<IContentsProps[]>();
	const [dataPedag, setDataPedag] = useState<IContentsProps[]>();

	const [updateContent, { error: errorUpdateContent }] = useMutation(UPDATE_CONTENT);

	const handleDelete = useCallback(
		async (id) => {
			await updateContent({
				variables: {
					id: parseInt(id),
					is_active: 0,
				},
			});

			errorUpdateContent
				? alert('Erro: Não foi possível excluir a página selecionada!')
				: alert('Página excluída com sucesso!');
		},
		[errorUpdateContent, updateContent],
	);

	const handleModalDelete = useCallback(
		(id) => {
			handleDelete(id);
		},
		[handleDelete],
	);

	const { loading } = useQuery(CONTENTS, {
		onCompleted: (data) => {
			let contents: IContentsProps[] = data.contents;

			setDataSchool(
				contents.filter((el) => {
					return el.page === 'escola';
				}),
			);

			setDataPedag(
				contents.filter((el) => {
					return el.page === 'proposta_pedagogica';
				}),
			);
		},
	});

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<div className="contentContainer">
				<PageCard>
					<CardTitle>
						<h3>Escola</h3>
					</CardTitle>

					<CardHeader>
						<span className="subpage">Páginas</span>
					</CardHeader>

					{dataSchool?.map((data) => (
						<Card key={data.name}>
							<span className="subpage">{data.name}</span>

							<div className="buttons">
								<Link to={`/paginas/${data.id}`} className="editButton">
									Editar
								</Link>

								<button className="deleteButton" onClick={() => handleModalDelete(data.id)}>
									Remover
								</button>
							</div>
						</Card>
					))}
				</PageCard>

				<PageCard>
					<CardTitle>
						<h3>Proposta Pedagógica</h3>
					</CardTitle>

					<CardHeader>
						<span className="subpage">Páginas</span>
					</CardHeader>

					{dataPedag?.map((data) => (
						<Card key={data.name}>
							<span className="subpage">{data.name}</span>

							<div className="buttons">
								<Link to={`/paginas/${data.id}`} className="editButton">
									Editar
								</Link>

								<button className="deleteButton" onClick={() => handleModalDelete(data.id)}>
									Remover
								</button>
							</div>
						</Card>
					))}
				</PageCard>
			</div>
		</Container>
	);
};

export default ContentsTable;
