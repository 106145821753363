import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';

import logoCEJ from '../../assets/logo_encanto.svg';

import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';

import { Container, LoginBox, Logo } from './styles';

const ForgotPassword: React.FC = () => {
	const { signIn, loading } = useAuth();

	const history = useHistory();

	const handleBack = useCallback(() => {
		history.push('/login');
	}, [history]);

	const handleSubmit = useCallback(
		async (dataForm, { reset }) => {
			await signIn(dataForm);

			reset();
		},
		[signIn],
	);

	return (
		<Container>
			<LoginBox>
				<Logo>
					<img src={logoCEJ} alt="Colégio Encanto Juvenil" />
				</Logo>

				<Form onSubmit={handleSubmit}>
					<h3>Recuperar Senha</h3>

					<Input name="email" placeholder="E-mail" required />

					<div className="buttons">
						<Button onClick={handleBack} className="backButton">
							Voltar
						</Button>

						<Button type="submit" loading={loading}>
							Enviar
						</Button>
					</div>
				</Form>
			</LoginBox>
		</Container>
	);
};

export default ForgotPassword;
