import styled from 'styled-components';

export const Container = styled.main`
	width: 100vw;
	height: 100vh;
	background-color: #f8f9fe;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 280px;
		margin-bottom: 5px;
	}
`;

export const Spinner = styled.div`
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;

	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #0f2a9a;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	& div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}

	& div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	& div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	& div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}

	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
`;
