import React from 'react';

import { Container, Spinner } from './styles';

import logo from '../../assets/logo.png';

const LoadingPage: React.FC = () => {
	return (
		<Container>
			<img src={logo} alt="Colégio Encanto Juvenil" />

			<Spinner>
				<div />
				<div />
				<div />
				<div />
			</Spinner>
		</Container>
	);
};

export default LoadingPage;
