import styled from 'styled-components';

import { lightNeutrals, mainColors } from '../../styles/colors';

export const Container = styled.div`
	width: 100%;

	display: flex;
	justify-content: center;

	.contentContainer {
		width: 90%;
		margin-top: 50px;
		border-radius: 8px;
	}
`;

export const PageCard = styled.section`
	width: 100%;
	max-width: 700px;
	background-color: #fff;

	margin: 30px auto 0px;
	padding: 0 0 30px;

	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 0.375rem;
`;

export const CardTitle = styled.div`
	width: 100%;
	height: 80px;
	padding: 0 20px;
	margin-bottom: 32px;
	border-bottom: 1px solid ${lightNeutrals.gray400};

	display: flex;
	align-items: center;
	justify-content: space-between;

	h3 {
		font-size: 22px;
		line-height: 30px;
		color: ${lightNeutrals.gray800};
	}

	a {
		text-decoration: none;
		padding: 8px 15px;
		border: 0;
		border-radius: 4px;
		font-size: 14px;
		color: ${lightNeutrals.gray800};
		background-color: ${mainColors.warning};
	}
`;

export const Form = styled.form`
	padding: 0 20px;

	.field {
		width: 100%;
		margin-bottom: 35px;

		display: flex;
		flex-direction: column;

		label {
			color: ${lightNeutrals.gray700};
			font-weight: 600;
			margin-bottom: 10px;
		}

		input {
			height: 40px;
			padding: 10px;
			color: ${lightNeutrals.gray800};
			color: ${mainColors.orangeSite};
			border: 1px solid ${lightNeutrals.gray400};
			border-radius: 0.25rem;
		}
	}

	.editor {
		color: ${mainColors.blueSite};
	}

	button {
		margin: 0 auto;
		padding: 10px 50px;
		text-align: center;

		border: 0;
		border-radius: 4px;
		background: ${mainColors.sucess};

		display: block;
	}
`;
