import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { RiArrowDownSFill } from 'react-icons/ri';

import { Container, Login, MenuProfile } from './styles';

import logoCEJ from '../../assets/logo_encanto.svg';

const QUERY_ME = gql`
	query {
		me {
			name
			email
		}
	}
`;

interface IUserProps {
	name: string;
	email?: string;
}

const Header: React.FC = () => {
	const [user, setUser] = useState<IUserProps>();
	const [showMenuProfile, setShowMenuProfile] = useState(true);

	const menuSection = document.querySelector('.menu-section');

	const handleMenuProfile = useCallback(() => {
		menuSection?.classList.toggle('on', showMenuProfile);

		setShowMenuProfile(!showMenuProfile);
	}, [menuSection, showMenuProfile]);

	useQuery(QUERY_ME, {
		onCompleted: (data) => {
			setUser(data.me);
		},
	});

	return (
		<Container>
			<Login onClick={handleMenuProfile}>
				<img src={logoCEJ} alt="Colégio Encanto Juvenil" />

				<span>{user?.name}</span>

				<RiArrowDownSFill size={16} />
			</Login>

			<MenuProfile className="menu-section">
				<Link to="/perfil/alterar-senha">Alterar Senha</Link>
				<Link to="/logout">Sair</Link>
			</MenuProfile>
		</Container>
	);
};

export default Header;
