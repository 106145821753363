import React from 'react';

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

import ChangePassword from '../../screens/ChangePassword';

import { Container } from './styles';

const ContentPages: React.FC = () => {
	return (
		<Container className="wrapper">
			<SideMenu />

			<div className="rightSide">
				<Header />

				<ChangePassword />
			</div>
		</Container>
	);
};

export default ContentPages;
