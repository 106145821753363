import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ContentPages from '../pages/ContentPages';
import Banners from '../pages/Banners';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword'
import Galleries from '../pages/Galleries';
import Home from '../pages/Home';
import Profiles from '../pages/Profiles'
import SignIn from '../pages/SignIn';
import SignOut from '../pages/SignOut';
import Socials from '../pages/Socials';

import PrivateRoute from './PrivateRoute';

const routes: React.FC = () => (
	<Switch>
		<PrivateRoute path="/" exact component={Dashboard} />
		<PrivateRoute path="/banners/" exact component={Banners} />
		<PrivateRoute path="/home/" exact component={Home} />
		<PrivateRoute path="/galerias/" exact component={Galleries} />
		<PrivateRoute path="/paginas" exact component={ContentPages} />
		<PrivateRoute path="/paginas/:id" exact component={ContentPages} />
		<PrivateRoute path="/sociais/" exact component={Socials} />

		<PrivateRoute path='/perfil/alterar-senha' exact component={Profiles} />
		<Route path='/perfil/recuperar-senha' exact component={ForgotPassword} />

		<Route path="/login" component={SignIn} />
		<Route path="/logout" component={SignOut} />

	</Switch>
	);

export default routes;
