import styled from 'styled-components';
import { shade } from 'polished';

import { mainColors, lightNeutrals } from '../../../styles/colors';

export const Container = styled.div`
	width: 100%;

	display: flex;
	justify-content: center;

	.contentContainer {
		width: 90%;
		margin-top: 50px;
		border-radius: 8px;
	}
`;

export const PageCard = styled.section`
	width: 100%;
	max-width: 700px;
	background-color: #fff;

	margin: 50px auto 0px;
	padding: 0 0 30px;

	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 0.375rem;
`;

export const CardTitle = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;

	h3 {
		margin-left: 15px;
		font-size: 17px;
		line-height: 24px;
		color: ${lightNeutrals.gray800};
	}
`;

export const CardHeader = styled.div`
	width: 100%;
	min-height: 40px;
	padding: 0 15px;
	font-size: 0.65rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;

	color: ${lightNeutrals.gray600};
	background-color: ${lightNeutrals.gray100};
	border-top: 1px solid ${lightNeutrals.gray200};
	border-bottom: 1px solid ${lightNeutrals.gray200};

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Card = styled.div`
	width: 100%;
	min-height: 50px;
	padding: 0 15px;
	background: #fff;
	border-bottom: 1px solid ${lightNeutrals.gray200};
	color: ${lightNeutrals.gray700};
	font-size: 0.875rem;
	font-weight: 600;

	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		width: 200px;
		/* height: 150px; */
		margin: 10px 0;
		object-fit: scale-down;
	}

	.buttons {
		width: 140px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		a,
		button {
			text-decoration: none;
			padding: 4px 8px;
			border: 0;
			border-radius: 4px;
			font-size: 14px;
			color: #fff;
		}

		.editButton {
			background-color: ${mainColors.sucess};

			&:hover {
				background-color: ${shade(0.2, mainColors.sucess)};
			}
		}

		.deleteButton {
			background-color: ${mainColors.danger};
			color: #fff;
			font-size: 14px;
			font-weight: 500;

			&:hover {
				background-color: ${shade(0.2, mainColors.danger)};
			}
		}
	}
`;
