import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import LoadingPage from '../../../components/LoadingPage';

import { Container, PageCard, CardTitle, CardHeader, Card } from './styles';

interface IBannersProps {
	id: number;
	name: string;
	url_image: string;
	redirect_link: string;
	is_active: number;
}

const BANNERS = gql`
	query {
		banners {
			id
			name
			url_image
			redirect_link
			is_active
		}
	}
`;

const ListBanners: React.FC = () => {
	// const [banners, setBanners] = useState<IBannersProps[]>();
	const [activeBanners, setActiveBanners] = useState<IBannersProps[]>();

	const { loading } = useQuery(BANNERS, {
		onCompleted: (data) => {
			let dataBanners: IBannersProps[] = data.banners;

			// setBanners(dataBanners);

			setActiveBanners(
				dataBanners.filter((el) => {
					return el.is_active === 1;
				}),
			);
		},
	});

	const handleDelete = useCallback(async (id) => {
		// await updateContent(
		// 	{variables:{
		// 		id: parseInt(id),
		// 		is_active: 0
		// 	}}
		// );

		// errorDeleteContent
		// ? alert("Erro: Não foi possível excluir a página selecionada!")
		// : alert("Página excluída com sucesso!");
	}, []);

	const handleModalDelete = useCallback(
		(id) => {
			handleDelete(id);
		},
		[handleDelete],
	);

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<div className="contentContainer">
				<PageCard>
					<CardTitle>
						<h3>Banners</h3>
					</CardTitle>

					<CardHeader>
						<span className="subpage">Ativos</span>
					</CardHeader>

					{/* id
			name
			url_image
			redirect_link
			is_active */}

					{activeBanners?.map((data) => (
						<Card key={data.id}>
							<img src={data.url_image} alt={data.name} />

							<span className="subpage">{data.name}</span>

							<div className="buttons">
								<Link to={`/paginas/${data.id}`} className="editButton">
									Editar
								</Link>

								<button className="deleteButton" onClick={() => handleModalDelete(data.id)}>
									Remover
								</button>
							</div>
						</Card>
					))}
				</PageCard>
			</div>
		</Container>
	);
};

export default ListBanners;
