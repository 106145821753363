import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './auth';

import { client } from '../services/api';

const AppProvider: React.FC = ({ children }) => (
	<ApolloProvider client={client}>
		<AuthProvider>{children}</AuthProvider>
	</ApolloProvider>
);

export default AppProvider;
