import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';

import logoCEJ from '../../assets/logo_encanto.svg';

import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';

import { Container, LoginBox, Logo } from './styles';

const SignIn: React.FC = () => {
	const { signIn, loading } = useAuth();

	const handleSubmit = useCallback(
		async (dataForm, { reset }) => {
			await signIn(dataForm);

			reset();
		},
		[signIn],
	);

	return (
		<Container>
			<LoginBox>
				<Logo>
					<img src={logoCEJ} alt="Colégio Encanto Juvenil" />
				</Logo>

				<Form onSubmit={handleSubmit}>
					<Input name="email" placeholder="Usuário" required />

					<Input name="password" type="password" placeholder="Senha" required />

					<Button type="submit" loading={loading}>
						Entrar
					</Button>

					<Link to={`/perfil/recuperar-senha`} className="forgotPassword">
						Esqueceu sua senha?
					</Link>
				</Form>
			</LoginBox>
		</Container>
	);
};

export default SignIn;
