import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Container, Content, Title, Form } from './styles';

interface IFormProps {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const CHANGE_PASSWORD = gql`
	mutation changePassword($current_password: String, $new_password: String) {
		changePassword(current_password: $current_password, new_password: $new_password) {
			status
		}
	}
`;

const ChangePassword: React.FC = () => {
	const [fields, setFields] = useState<IFormProps>({
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	} as IFormProps);

	const [changePassword, { error: errorChangePassword }] = useMutation(CHANGE_PASSWORD);

	const handleFieldChange = useCallback(
		(e) => {
			setFields({ ...fields, [e.target.name]: e.target.value });
		},
		[fields],
	);

	const handleReset = useCallback(() => {
		setFields({
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		});
	},[]);

	const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			const { currentPassword, newPassword, confirmPassword } = fields;

			if (!currentPassword || !newPassword || !confirmPassword) {
				alert('Todos os campos devem ser preenchidos');
				return;
			}

			if (newPassword !== confirmPassword) {
				alert('A confirmação de senha não é igual a nova senha.');
				return;
			}

			const response = await changePassword({variables: {
				current_password: fields.currentPassword,
				new_password: fields.newPassword,
			}});

			if (
				errorChangePassword
				|| !response.data.changePassword.status
			) {
				alert('Erro ao salvar a nova senha!');
				return;
			}

			alert("Senha alterada com sucesso.");
			handleReset();
		},
		[fields, changePassword, errorChangePassword, handleReset],
	);

	return (
		<Container>
			<Content>
				<Title>
					<h3>Alterar Senha</h3>
				</Title>

				<Form onSubmit={handleSubmit}>
					<div className="field">
						<label>Senha Atual</label>

						<input
							id="currentPassword"
							type="password"
							name="currentPassword"
							placeholder="Senha Atual"
							value = {fields.currentPassword}
							onChange={handleFieldChange}
						/>
					</div>

					<div className="field">
						<label>Nova Senha</label>

						<input
							id="newPassword"
							type="password"
							name="newPassword"
							placeholder="Nova Senha"
							value = {fields.newPassword}
							onChange={handleFieldChange}
						/>
					</div>

					<div className="field">
						<label>Confirme a Nova Senha</label>

						<input
							id="confirmPassword"
							type="password"
							name="confirmPassword"
							placeholder="Confirme a Senha"
							value = {fields.confirmPassword}
							onChange={handleFieldChange}
						/>
					</div>

					<button type="submit">SALVAR</button>
				</Form>
			</Content>
		</Container>
	);
};

export default ChangePassword;
