import React from 'react';
import { useParams } from 'react-router-dom';

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

import ListBanners from '../../screens/Banners/ListBanners';
import UnitContentPage from '../../screens/UnitContentPage';

import { Container } from './styles';

interface IParamsProps {
	id: string;
}

const Banners: React.FC = () => {
	const { id } = useParams<IParamsProps>();

	return (
		<Container className="wrapper">
			<SideMenu menuActive="textos" />

			<main className="rightSide">
				<Header />

				{id ? <UnitContentPage /> : <ListBanners />}
			</main>
		</Container>
	);
};

export default Banners;
