import React, { useEffect } from 'react';

import { useAuth } from '../../hooks/auth';

import LoadingPage from '../../components/LoadingPage';

const SignOut: React.FC = () => {
	const { signOut } = useAuth();

	useEffect(() => {
		signOut();
	}, [signOut]);

	return <LoadingPage />;
};

export default SignOut;
