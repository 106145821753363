import React, { useState } from 'react';
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import LoadingPage from '../components/LoadingPage';

interface RouteProps extends ReactDOMRouteProps {
	component: React.ComponentType;
}

interface IUserProps {
	name: string | null;
}

const PROFILE_USER = gql`
	query {
		me {
			id
			name
			email
		}
	}
`;

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
	const [user, setUser] = useState<IUserProps[] | null>();

	const { loading } = useQuery(PROFILE_USER, {
		onCompleted: (data) => {
			if (data.me === null || data.me) {
				setUser(data.me);
			}
		},
	});

	if (loading || user === undefined) {
		return <LoadingPage />;
	}

	return (
		<>
			<ReactDOMRoute
				{...rest}
				render={({ location }) => {
					return user ? <Component /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />;
				}}
			/>
		</>
	);
};

export default PrivateRoute;
