import React, { ButtonHTMLAttributes } from 'react';

import { Spinner } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, loading = false, ...rest }) => {
	return (
		<button type="button" {...rest}>
			{!loading ? children : <Spinner />}
		</button>
	);
};

export default Button;
