import styled from 'styled-components';
import { shade } from 'polished';

import { lightNeutrals, mainColors } from '../../styles/colors';

export const Container = styled.div`
	width: 100%;
	display: flex;
`;

export const Content = styled.section`
	width: 97%;

	margin: 90px auto 0px;
	padding: 0 0 30px;
`;

export const Title = styled.div`
	width: 100%;
	height: 80px;
	padding: 20px;
	margin-bottom: 18px;
	border-bottom: 1px solid ${lightNeutrals.gray400};
	box-shadow: 4px 4px 4px ${lightNeutrals.gray400};

	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: space-between;

	h3 {
		font-size: 22px;
		line-height: 30px;
		color: ${lightNeutrals.gray800};
	}
`;

export const Form = styled.form`
	padding: 20px;
	background-color: #fff;
	border-bottom: 1px solid ${lightNeutrals.gray400};
	box-shadow: 4px 4px 4px ${lightNeutrals.gray400};

	.field {
		width: 100%;
		margin-bottom: 35px;

		display: flex;
		flex-direction: column;

		label{
			color: ${lightNeutrals.gray800};
			font-weight: 600;
			margin-bottom: 10px;
		}

		input {
			width: 50%;
			height: 40px;
			padding: 10px;
			color: ${mainColors.main};
			border: 1px solid ${lightNeutrals.gray400};
			border-radius: .25rem;
		}

		input:focus {
			border: 2px solid ${mainColors.orangeSite};
		}
	}

	.editor {
		color: ${mainColors.blueSite};
	}

	button {
		padding: 10px 50px;
		text-align: center;
		font-weight: 600;
		color: ${lightNeutrals.gray100};

		border: 0;
		border-radius: 4px;
		box-shadow: 4px 4px 4px ${lightNeutrals.gray400};
		background: ${mainColors.sucess};

		display: block;

		&:hover {
			background-color: ${shade(0.2, mainColors.sucess)};
		}
	}
`;