import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { client } from '../services/api';

interface SignInCredentials {
	login: string;
	password: string;
}

interface AuthContextData {
	signIn(credentials: SignInCredentials): Promise<void>;
	signOut(): void;
	loading: boolean;
}
const ADD_LOGIN = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password)
	}
`;

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
	const [loading, setLoading] = useState(false);

	const [login] = useMutation(ADD_LOGIN);

	const history = useHistory();

	const signIn = useCallback(
		async ({ email, password }) => {
			setLoading(true);

			client.clearStore();

			const response = await login({ variables: { email, password } });

			const token = response.data.login;

			token && localStorage.setItem('@cej:tokenAuth', token);

			setLoading(false);

			if (!token) {
				alert('Usuário ou Senha Inválido');
				return;
			}

			history.push('/');
		},
		[history, login],
	);

	const signOut = useCallback(() => {
		client.resetStore();

		localStorage.removeItem('@cej:tokenAuth');

		history.push('/login');
	}, [history]);

	return <AuthContext.Provider value={{ signIn, loading, signOut }}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used withn an AuthProvider');
	}

	return context;
}
