import styled from 'styled-components';

import {lightNeutrals} from '../../styles/colors';

export const Container = styled.main`
	width: 100vw;
	height: 100vh;
	background: ${lightNeutrals.gray700};
	background: linear-gradient(90deg, rgba(82,95,127,1) 100%, rgba(0,212,255,1) 100%);


	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LoginBox = styled.section`
	width: 340px;
	padding: 20px;
	background: #f7fafc;
	border-radius: 8px;
	box-shadow: 4px 4px 4px rgba(136, 152, 170, .15);

	h3 {
		text-align: center;
		margin: 0px auto 20px;
	}

	input {
		width: 100%;
		padding: 10px;
		background: #fff;
		border-radius: 4px;
		border: 0;
		box-shadow: 0 1px 3px rgba(50, 50, 93, .35), 0 1px 0 rgba(0, 0, 0, .02);

		& + input {
			margin-top: 20px;
		}

		&:focus {
			box-shadow: 0 1px 3px rgba(50, 50, 93, .5), 0 1px 0 rgba(0, 0, 0, .2);
		}
	}

	.buttons {
		width: 100%;
		display: flex;

		.backButton {
			background-color: ${lightNeutrals.gray600};
			color: ${lightNeutrals.gray100};

			&:hover {
				background-color: ${lightNeutrals.gray400};
				color: ${lightNeutrals.gray800};
			}
		}

		button {
			width: 40%;
			height: 45px;
			margin: 25px auto 10px;

			cursor: pointer;
			background-color: #2d529e;
			color: #fff;
			border-radius: 8px;
			border: 0;
			box-shadow: 0 4px 6px rgba(50, 50, 93, .11);

			display: flex;
			align-items: center;
			justify-content: center;

			box-shadow: 0 1px 3px rgba(50, 50, 93, .35), 0 1px 0 rgba(0, 0, 0, .02);

			&:hover {
				background-color: #25488d;
			}
		}
	}
`;

export const Logo = styled.div`
	width: 100%;
	padding: 10px 0 32px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 130px;
	}
`;