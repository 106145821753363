export const mainColors = {
	main: '#172b4d',
	primary: '#5e72e4',
	secundary: '#f4f5f7',
	info: '#11cdef',
	sucess: '#2dce89',
	danger: '#f5365c',
	warning: '#ffd600',
	blueSite: '#0F2A9A',
	orangeSite: '#FF7F00',
};

export const lightNeutrals = {
	gray100: '#f6f9fc',
	gray200: '#e9ecef',
	gray300: '#dee2e6',
	gray400: '#ced4da',
	gray500: '#adb5bd',
	gray600: '#8898aa',
	gray700: '#525f7f',
	gray800: '#32325d',
	gray900: '#212529',
};
