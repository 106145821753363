import React from 'react';
import { NavLink } from 'react-router-dom';

import { RiDashboard2Line, RiPagesLine, RiHomeWifiLine, RiImage2Line, RiInstagramLine } from 'react-icons/ri';

import { Container, Logo, List, ListItem } from './styles';

import logo from '../../assets/logo.png';

interface ISideMenuProps {
	menuActive?: string;
}

const SideMenu: React.FC<ISideMenuProps> = ({ menuActive }) => {
	return (
		<Container>
			<Logo>
				<img src={logo} alt="Colégio Encanto Juvenil" />
			</Logo>

			<List>
				<ListItem className="dashboard">
					<NavLink to="/" exact activeClassName="selected">
						<RiDashboard2Line className="icoDashboard" size={22} />
						<span>Dashboard</span>
					</NavLink>
				</ListItem>

				<ListItem className="home">
					<NavLink to="/home" activeClassName="selected">
						<RiHomeWifiLine className="icoHome" size={22} />
						<span>Home</span>
					</NavLink>
				</ListItem>

				<ListItem className="textos">
					<NavLink to="/paginas" activeClassName="selected">
						<RiPagesLine className="icoPages" size={22} />
						<span>Páginas de Conteúdo</span>
					</NavLink>
				</ListItem>

				<ListItem className="galeria">
					<NavLink to="/galerias" activeClassName="selected">
						<RiImage2Line className="icoGallery" size={22} />
						<span>Galeria</span>
					</NavLink>
				</ListItem>

				<ListItem className="social">
					<NavLink to="/sociais" activeClassName="selected">
						<RiInstagramLine className="icoSocial" size={22} />
						<span>Rede Sociais</span>
					</NavLink>
				</ListItem>
			</List>
		</Container>
	);
};

export default SideMenu;
