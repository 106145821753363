import React, { useCallback, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Container, PageCard, CardTitle, Form } from './styles';

const QUERY_CONTENT = gql`
	query Content($id: Int!) {
		content(id: $id) {
			name
			description
		}
	}
`;

const UPDATE_CONTENT = gql`
	mutation updateContent($id: ID, $name: String, $description: String) {
		updateContent(id: $id, name: $name, description: $description) {
			id
		}
	}
`;

interface IFormProps {
	name: string;
	description: string;
}

interface IParamTypesProps {
	id: string;
}

const UnitContentPage: React.FC = () => {
	const [fields, setFields] = useState<IFormProps>({
		name: '',
		description: '',
	} as IFormProps);

	const history = useHistory();

	const { id } = useParams<IParamTypesProps>();

	const [updateContent, { error: errorUpdateContent }] = useMutation(UPDATE_CONTENT);

	const { loading } = useQuery(QUERY_CONTENT, {
		variables: { id },
		onCompleted: (data) => {
			!data.content[0] ? history.push('/paginas') : setFields(data.content[0]);
		},
	});

	const handleEditorChange = useCallback(
		(data) => {
			setFields({ ...fields, description: data });
		},
		[fields],
	);

	const handleTitleChange = useCallback(
		(e) => {
			setFields({ ...fields, name: e.target.value });
		},
		[fields],
	);

	const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			const { name, description } = fields;

			await updateContent({
				variables: {
					id: parseInt(id),
					name,
					description,
				},
			});

			errorUpdateContent
				? alert('Erro: Não foi possível atualizar o conteúdo!')
				: alert('Página atualizada com sucesso!');
		},
		[fields, id, updateContent, errorUpdateContent],
	);

	return (
		<Container>
			<div className="contentContainer">
				<PageCard>
					<CardTitle>
						<h3>Editar página</h3>

						<Link to="/paginas" className="buttonBack">
							Voltar
						</Link>
					</CardTitle>
					{loading ? (
						''
					) : (
						<Form onSubmit={handleSubmit}>
							<div className="field">
								<label>Menu</label>

								<input
									id="inputName"
									type="text"
									name="title"
									placeholder="Título"
									onChange={handleTitleChange}
									value={fields.name}
								/>
							</div>

							<div className="field">
								<label>Conteúdo</label>

								<Editor
									initialValue={fields.description}
									apiKey={process.env.REACT_APP_TOKEN_TINY}
									init={{
										language: 'pt_BR',
										height: 500,
										menubar: true,
										content_style: 'body { color: #172b4d; }',
										color_map: [
											'000000',
											'Preto',
											'808080',
											'Cinza',
											'FFFFFF',
											'Branco',
											'FF0000',
											'Vermelho',
											'FFFF00',
											'Amarelo',
											'008000',
											'Verde',
											'FF7F00',
											'Laranja',
											'172b4d',
											'Azul',
										],
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
									}}
									tagName="textarea"
									onEditorChange={handleEditorChange}
								/>
							</div>

							<button type="submit">ENVIAR</button>
						</Form>
					)}
				</PageCard>
			</div>
		</Container>
	);
};

export default UnitContentPage;
