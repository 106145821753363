import styled from 'styled-components';
import { shade } from 'polished';

import { mainColors, lightNeutrals } from '../../styles/colors';

export const Login = styled.div`
	margin-right: 40px;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		width: 36px;

		border-radius: 50%;
		border: 1px solid #fff;
		margin-right: 8px;
	}

	.data_user {
		margin-left: 15px;

		flex: 1;
		display: flex;
		flex-direction: column;

		p {
			font-weight: bold;
			font-size: 14px;
		}

		button {
			font-size: 14px;
			background: transparent;
			color: #fff;
			border: 0;
			margin-top: 5px;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			svg {
				margin-right: 5px;
			}

			&:hover {
				color: ${mainColors.orangeSite};
			}
		}
	}
`;

export const MenuProfile = styled.div`
	width: 200px;
	position: absolute;
	top: 75px;
	right: 0px;
	display: none;

	a {
		width: 100%;
		height: 100%;
		padding: 20px 0;
		z-index: 10;

		text-decoration: none;
		background-color: ${mainColors.main};
		color: ${mainColors.secundary};

		display: flex;
		align-items: center;
		justify-content: center;

		& + a {
			border-top: 2px solid ${lightNeutrals.gray200};
		}

		&:hover {
			background-color: ${shade(0.2, mainColors.main)};
			color: #fff;
		}
	}
`;

export const Container = styled.div`
	width: calc(100vw - 230px);
	height: 75px;
	background-color: ${mainColors.main};
	border-bottom: 1px solid ${lightNeutrals.gray200};

	position: fixed;

	box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.2);
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	.menu-section.on {
		display: block;
	}
`;
