import { createGlobalStyle } from 'styled-components';

import { lightNeutrals, mainColors } from './colors';

export default createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}

	body {
		background: ${lightNeutrals.gray200};
		color: ${mainColors.main};
		-webkit-font-smoothing: antialiased;
		overflow-x: hidden;
	}

	body,
	input,
	button,
	textarea,
	select,
	p{
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
		line-height: 20px;
	}

	h1 {
		font-family: 'Roboto', sans-serif;
		font-size: 39px;
		line-height: 59px;
	}

	h2 {
		font-family: 'Roboto', sans-serif;
		font-size: 28px;
		line-height: 42px;
	}

	h3 {
		font-family: 'Roboto', sans-serif;
		font-size: 22px;
		line-height: 32px;
	}

	button {
		cursor: pointer;
	}

	.wrapper{
		width: 100vw;
		height: 100%;

		.rightSide{
			width: calc(100vw - 230px);
			margin-left: 231px;
			padding-bottom: 100px;
		}
	}
`;
